import React from "react";
import "./contact.css";

import email from "../../assets/email.png";
import twitter from "../../assets/twitter.png";
import discord from "../../assets/discord.png";

const Contact = () => {
  return (
    <div className="dr__contact" id="contact">
      <div className="dr__contact-title">
        <h1>- You can find me on -</h1>
      </div>

      <div className="dr__contact-email">
        <a href="mailto: zystra.lab@gmail.com" target="_blank">
          <img src={email} alt="email" />
        </a>
        <p>zystra.lab@gmail.com</p>
      </div>

      <div className="dr__contact-twitter">
        <a href="https://twitter.com/0xZystra" target="_blank">
          <img src={twitter} alt="twitter" />
        </a>
        <p>@0xZystra</p>
      </div>

      <div className="dr__contact-discord">
        <a href="https://discord.com/" target="_blank">
          <img src={discord} alt="discord" />
        </a>
        <p>Zystra#1111</p>
      </div>

      <div className="dr__contact-footer">
        <p>© 2022 Kai Jian Teo</p>
      </div>
    </div>
  );
};

export default Contact;
