import React from "react";
import "./home.css";

import photo from "../../assets/photo.jpg";
import photo2 from "../../assets/photo2.png";
import minus from "../../assets/minus.png";

const Home = () => {
  return (
    <>
      <div className="dr__home" id="home">
        <div className="dr__home-pic">
          <img src={photo2} alt="photo" />
        </div>

        <div className="dr__home-intro">
          <p>Hello, I'm</p>
        </div>

        <div className="dr__home-name">
          <img src={minus} alt="line" />
          <h1>Kai Jian Teo</h1>
          <img src={minus} alt="line" />
        </div>

        <div className="dr__home-welcome">
          <p>Welcome To My WEB 3.0 Digital Resume</p>
        </div>
      </div>
    </>
  );
};

export default Home;
