import React, { useState } from "react";
import "./navbar.css";

import { RiMenu3Line, RiCloseLine } from "react-icons/ri";

import twitter from "../../assets/twitter.png";
import email from "../../assets/email.png";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className="dr__navbar">
      <div className="dr__navbar-title">
        <h1>
          <a href="#home">KAI</a>
        </h1>
      </div>

      <div className="dr__navbar-links">
        <p>
          <a href="#home">Home</a>
        </p>

        <p>
          <a href="#experience">Experience</a>
        </p>

        <p>
          <a href="#contact">Contact</a>
        </p>
      </div>

      <div className="dr__navbar-icons">
        <a href="https://twitter.com/0xZystra" target="_blank">
          <img src={twitter} alt="twitter" />
        </a>
        <a href="mailto: zystra.lab@gmail.com" target="_blank">
          <img src={email} alt="email" />
        </a>
      </div>

      <div className="dr__navbar-menu">
        {toggleMenu ? (
          <RiCloseLine
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <RiMenu3Line
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(true)}
          />
        )}
        {toggleMenu && (
          <div className="dr__navbar-menu_container scale-up-center">
            <div className="dr__navbar-menu_container-links">
              <p>
                <a href="#home">Home</a>
              </p>

              <p>
                <a href="#experience">Experience</a>
              </p>

              <p>
                <a href="#contact">Contact</a>
              </p>
            </div>
            <div className="dr__navbar-menu_container-icons">
              <a href="https://twitter.com/0xZystra" target="_blank">
                <img src={twitter} alt="twitter" />
              </a>
              <a href="mailto: zystra.lab@gmail.com" target="_blank">
                <img src={email} alt="email" />
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
