import "./App.css";
import { Navbar, Home, Experience, Contact } from "./components";

const App = () => {
  return (
    <div className="App">
      <Navbar />
      <Home />
      <Experience />
      <Contact />
    </div>
  );
};

export default App;
