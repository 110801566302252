import React from "react";
import "./experience.css";

import test from "../../assets/test.jpg";

import inarium from "../../assets/inarium.jpg";
import x from "../../assets/100x.jpg";
import tegs from "../../assets/tegs.png";
import cw from "../../assets/cw.jpg";

const Experience = () => {
  return (
    <div className="dr__experience" id="experience">
      <div className="dr__experience-title">
        <h3>Experience</h3>
      </div>

      <div className="dr__experience-job1">
        <div className="dr__experience-job1__info">
          <div className="dr__experience-job1__info-title">
            <p>Project Consultant</p>
          </div>
          <div className="dr__experience-job1__info-company">
            <p>Inarium</p>
          </div>
          <div className="dr__experience-job1__info-points">
            <ul>
              <li>
                Consulting the company in the direction of lauching a NFT
                project.
              </li>

              <li>
                Consultations include basics of NFT project, social media
                planning, discord server planning, designing/building website
                and etc.
              </li>

              <li>Overlooking the entire project to ensure a smooth launch.</li>
            </ul>
          </div>
        </div>
        <div className="dr__experience-job__photo">
          <img src={inarium} alt="inarium" />
        </div>
      </div>

      <div className="dr__experience-job2">
        <div className="dr__experience-job__photo">
          <img src={x} alt="100x" />
        </div>

        <div className="dr__experience-job2__info">
          <div className="dr__experience-job2__info-title">
            <p>Tech Lead/Operations Manager</p>
          </div>
          <div className="dr__experience-job2__info-company">
            <p>100X Alpha Club</p>
          </div>
          <div className="dr__experience-job2__info-points">
            <ul>
              <li>
                100X Alpha Club main focus is to convert and educate
                individuals/companies from web 2.0 who are interested in
                learning web 3.0.
              </li>

              <li>
                Joined 100X initially as a Community Moderator but shortly after
                was promoted Tech Lead/Operations Manager to better assist the
                company.
              </li>

              <li>
                Main job scope includes overseeing every tech aspect of the
                company including website, dashboard, discord server, etc.
              </li>

              <li>
                Another role includes ensuring operations are ran smoothly and
                effectively, providing support to members, researching and
                providing NFT alpha calls.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="dr__experience-job1">
        <div className="dr__experience-job1__info">
          <div className="dr__experience-job1__info-title">
            <p>Community Moderator</p>
          </div>
          <div className="dr__experience-job1__info-company">
            <p>CryptoWalkers</p>
          </div>
          <div className="dr__experience-job1__info-points">
            <ul>
              <li>
                Was referred to join CryptoWalkers as a Community Moderator
                since the start of the project.
              </li>

              <li>
                Assisted in building the project in the right direction before
                it went public.
              </li>

              <li>
                Job scope includes providing customer support, building the
                project, finding collaborations, engaging with members and many
                more.
              </li>
            </ul>
          </div>
        </div>
        <div className="dr__experience-job__photo">
          <img src={cw} alt="cw" />
        </div>
      </div>

      <div className="dr__experience-job2">
        <div className="dr__experience-job__photo">
          <img src={tegs} alt="tegs" />
        </div>

        <div className="dr__experience-job2__info">
          <div className="dr__experience-job2__info-title">
            <p>Community Lead/Moderator</p>
          </div>
          <div className="dr__experience-job2__info-company">
            <p>The Exotic Gentlemen Society (TEGS)</p>
          </div>
          <div className="dr__experience-job2__info-points">
            <ul>
              <li>
                Joined the team as Community Moderator after two weeks of being
                an active member in TEGS’s server.
              </li>

              <li>
                Mainly focuses on providing customer support, moderating chat
                channels, engaging with members and hosting events.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experience;
